<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(FESTIVAL_BONUS_POLICY_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->

          <template v-if="props.column.field === 'format_title'">
            <b>{{ props.row.title }}</b>
          </template>

          <template v-if="props.column.field === 'format_amount_value_type'">
            <template v-if="props.row.amount_value_type == 'flat'">
              <b-badge variant="light-success">
                {{ (props.row.amount_value_type).toUpperCase() }}
              </b-badge>
            </template>
            <template v-else>
              <b-badge variant="light-warning">
                {{ (props.row.amount_value_type).toUpperCase() }}
              </b-badge>
            </template>
          </template>

          <template v-if="props.column.field === 'format_currency'">

            <template v-if="props.row.amount_value_type !== 'percentage'">
              <FormatCurrency :type="'currency'"  :currency="defaultCurrency" />
            </template>
            <template v-else>
              -
            </template>

          </template>

          <template v-if="props.column.field === 'format_amount_value'">
            <template v-if="props.row.amount_value_type == 'percentage'">
              <b-badge variant="light-primary">
                {{ formatAmount(props.row) }}
              </b-badge>
            </template>
            <template v-else>
              <FormatCurrency :amount="props.row?.amount_value" :currency="defaultCurrency" />
            </template>
          </template>
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="
                    $permissionAbility(FESTIVAL_BONUS_POLICY_EDIT, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <!-- <template
                  v-if="
                    $permissionAbility(FESTIVAL_BONUS_POLICY_EDIT, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onShowPage(props.row)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template> -->

                <template
                  v-if="
                    $permissionAbility(
                      FESTIVAL_BONUS_POLICY_DELETE,
                      permissions
                    )
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-festival-bonus-policy-from"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Festival Bonus Policy'
          : 'Create Festival Bonus Policy'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >

      <validation-observer ref="addFestivalBonusPolicy">
        <b-form v-on:submit.prevent="addFestivalBonusPolicyForm">
          <!-- name -->
          <b-form-group label="Title" label-for="title" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="title"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter Policy Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <template v-if="amountValueType == 'percentage'">
            <b-form-group
                label="Salary Type "
                label-for="salary_type"
                class="required-label"
            >
              <ValidationProvider
                  name="salary type"
                  v-slot="{ errors }"
                  vid="salary_type"
                  rules="required"
              >
                <v-select
                    id="salary_type"
                    v-model="salaryType"
                    :options="salaryTypeConstants"
                    :reduce="(option) => option.value"
                    label="name"
                    placeholder="Choose Here"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

          <b-form-group
            label="Type "
            label-for="amount_value_type"
            class="required-label"
          >
            <ValidationProvider
              name="type"
              v-slot="{ errors }"
              vid="amount_value_type"
              rules="required"
            >
              <v-select
                id="amount_value_type"
                v-model="amountValueType"
                :options="filteredAmountValueTypeConstants"
                :reduce="(option) => option.value"
                label="name"
                placeholder="Choose Here"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <template v-if="amountValueType == 'percentage'">
            <b-form-group
              label="Amount (%)"
              label-for="amount_value"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="amount"
                vid="amount_value"
                rules="required|min_value:1"
              >
                <b-form-input
                  id="amount_value"
                  type="text"
                  v-model="amount"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Amount"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <template v-else>
            <b-form-group
              label="Amount "
              label-for="amount_value"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="amount"
                vid="amount_value"
                rules="required|min_value:1"
              >
                <b-form-input
                  id="amount_value"
                  type="text"
                  v-model="amount"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Amount"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <!-- loading button -->
          <template v-if="isFestivalBonusPolicyLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

    <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  VBTooltip, BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import { salaryTypeConstants } from "@/helpers/constant/salaryTypeConstant";
import { amountValueTypeConstants } from "@/helpers/constant/amountValueTypeConstant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  FESTIVAL_BONUS_POLICY_CREATE,
  FESTIVAL_BONUS_POLICY_EDIT,
  FESTIVAL_BONUS_POLICY_DELETE,
  FESTIVAL_BONUS_POLICY_SHOW,
} from "@/helpers/permissionsConstant";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "FestivalBonusPolicyView",
  components: {
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      FESTIVAL_BONUS_POLICY_CREATE,
      FESTIVAL_BONUS_POLICY_EDIT,
      FESTIVAL_BONUS_POLICY_DELETE,
      FESTIVAL_BONUS_POLICY_SHOW,

      modelType: "",
      defaultCurrency: "",

      festivalBonusPolicyId: "",
      salaryTypeConstants,
      salaryType: "",
      filteredAmountValueTypeConstants: [],
      amountValueTypeConstants,
      amountValueType: "",
      title: "",
      amount: "",


      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Type",
          field: "format_amount_value_type",
          sortable: false,
          formatFn: this.formatAmountType,
        },
        {
          label: "Amount",
          field: "format_amount_value",
          sortable: true,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          sortable: true,
          formatFn: this.formatDate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isFestivalBonusPolicyLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [getSetting, getCurrencies] =
          await Promise.all([
            this.getSetting({
              select:
                  "currency_id",
            }),
            this.getCurrencies(),
          ]);

      // getSetting
      const currencyId = getSetting?.data?.data?.currency_id;

      // getCurrencies
      const currencyData = getCurrencies?.data?.data?.filter((item) => {
        return item?.id === currencyId
      });

      this.defaultCurrency = currencyData?.[0]?.currency;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    // onShowPage(value) {
    //   let policyCategoryName = "festival-bonus-policy";

    //   let policyCategoryId = value?.policy_category_id;
    //   let id = value?.id;
    //   this.$router.push({
    //     name: "admin-payroll-policy-assign-details",
    //     params: { policyCategoryName, policyCategoryId, id },
    //   });
    // },

    filterAmountType() {
      const lastIndex = this.amountValueTypeConstants.length - 1;
      this.filteredAmountValueTypeConstants =
        this.amountValueTypeConstants.slice(0, lastIndex);
    },
    showModal() {
      this.filterAmountType();
      this.$bvModal.show("modal-festival-bonus-policy-from");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-festival-bonus-policy-from");
      this.resetModal();
    },
    resetModal() {
      this.festivalBonusPolicyId = "";
      this.salaryType = "";
      this.amountValueType = "";
      this.title = "";
      this.amount = "";

    },
    async onShow(value) {
      this.modelType = "editModel";

      this.festivalBonusPolicyId = value?.id;
      this.salaryType = value?.salary_type;
      this.amountValueType = value?.amount_value_type;
      this.title = value?.title;
      this.amount = value?.amount_value;


      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/festival-bonus-policies/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    formatAmount(row) {
      if (row?.amount_value_type == "percentage") {
        return `${row?.amount_value} %`;
      }
      return row?.amount_value + " " + this.defaultCurrency;
    },
    formatAmountType(value) {
      const amountType = amountValueTypeConstants.find(
        (item) => item.value === value
      );
      return amountType?.name;
    },

    formatSalaryType(value) {
      const salaryType = salaryTypeConstants.find(
        (item) => item.value === value
      );
      return salaryType?.name;
    },
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getFestivalBonusPolicies(params) {
      return await this.$api.get("api/festival-bonus-policies", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async getUsers() {
      return await this.$api.get("api/users/all?include=designation");
    },

    async loadItems() {
      try {
        const festivalBonusPolicies = await this.getFestivalBonusPolicies({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = festivalBonusPolicies?.data?.data;

        const meta = festivalBonusPolicies?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    addFestivalBonusPolicyForm: async function () {
      this.$refs.addFestivalBonusPolicy.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isFestivalBonusPolicyLoading = true;
              await this.$api.put(
                `/api/festival-bonus-policies/${this.festivalBonusPolicyId}`,

                {
                  title: this.title,
                  amount_value_type: this.amountValueType,
                  amount_value: this.amount,
                  salary_type: this.salaryType,

                }
              );
              this.isFestivalBonusPolicyLoading = false;
              this.hiddenModal();
              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Festival Bonus Policy Successfully Updated",
                },
              });
            } else {
              this.isFestivalBonusPolicyLoading = true;

              await this.$api.post(
                "/api/festival-bonus-policies",

                {
                  title: this.title,
                  amount_value_type: this.amountValueType,
                  amount_value: this.amount,
                  salary_type: this.salaryType,

                }
              );
              this.isFestivalBonusPolicyLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Festival Bonus Policy Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isFestivalBonusPolicyLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.addFestivalBonusPolicy.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

    <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
